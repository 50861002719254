.acc-table .table{
    text-align: center;
}

.acc-table .table th{
    font-weight: 400;
    padding: 1rem .75rem;
    vertical-align: bottom;
    color: #555;
}
.acc-table .table thead tr:nth-child(2) th{
    padding: 1rem .75rem;
}

.acc-table .table thead tr:nth-child(1) th:not(:first-child):not(:last-child){
    padding: 1rem .75rem .5rem .75rem;
    color: #3DD84E;
    text-transform: uppercase;
}

.acc-table .table td{
    padding: 1rem .75rem;
}

.acc-table .table thead tr:nth-child(1) th{
    border-bottom: 1px solid #e5e7eb;
}

.acc-table .table thead tr:nth-child(1) th:not(:last-child){
    border-right: 1px solid #e5e7eb;
}

.acc-table .table thead tr:nth-child(2) th:not(:last-child){
    border-right: 1px solid #e5e7eb;
}

.acc-table .table tr td:not(:last-child){
    border-right: 1px solid #e5e7eb;
}